import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";

export interface ISupplier {
    id: number;
    cqmsID: number;
    isDormant: boolean;
    doNotChase: boolean;
    name: string;
    companyLegalName: string;
    address1: string;
    address2: string;
    address3: string;
    postTown: string;
    county: string;
    postcode: string;
    countryID: number;
    telephone: string;
    fax: string;
    website: string;
    email: string;
    ssipTradeDescription: string;
    numberOfEmployees: number;
    numberOfSubcontractors: number;
    companyTypeID: number;
    vatNumber: string;
    companyRegNumber: string;
    utrNumber: string;
    niNumber: string;
    bankName: string;
    bankAddress: string;
    bankAccountName: string;
    bankSortCode: string;
    bankAccountNumber: string;
    factoringCompany: string;
    remittanceEmail: string;
    hasNotPaid: boolean;
    isConsultant: boolean;
    isContractor: boolean;
    isCqmsClient: boolean;
    notes: string;
    coinsIdentifier: string;
    sageAC: string;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    dormantDate: Date;

    amFollowing: boolean;

    tradeIDs: Array<number>;
    tradingAreaIDs: Array<number>;
}

export class Supplier implements ISupplier {

    constructor(data?: ISupplier) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISupplier) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    cqmsID: number = 0;
    isDormant: boolean = false;
    doNotChase: boolean = false;
    name: string = "";
    companyLegalName: string = "";
    address1: string = "";
    address2: string = "";
    address3: string = "";
    postTown: string = "";
    county: string = "";
    postcode: string = "";
    countryID: number = 0;
    telephone: string = "";
    fax: string = "";
    website: string = "";
    email: string = "";
    ssipTradeDescription: string = "";
    numberOfEmployees: number = 0;
    numberOfSubcontractors: number = 0;
    companyTypeID: number = 0;
    vatNumber: string = "";
    companyRegNumber: string = "";
    utrNumber: string = "";
    niNumber: string = "";
    bankName: string = "";
    bankAddress: string = "";
    bankAccountName: string = "";
    bankSortCode: string = "";
    bankAccountNumber: string = "";
    factoringCompany: string = "";
    remittanceEmail: string = "";
    hasNotPaid: boolean = false;
    isConsultant: boolean = false;
    isContractor: boolean = false;
    isCqmsClient: boolean = false;
    notes: string = "";
    coinsIdentifier: string = "";
    sageAC: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    dormantDate: Date = new Date(utils.emptyDateValue);

    amFollowing: boolean = false;

    tradeIDs: Array<number> = [];
    tradingAreaIDs: Array<number> = [];

    get isActive() {
        return !this.isDormant;
    }
    set isActive(isActive: boolean) {
        this.isDormant = !isActive;
    }

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }

    get address() {
        let address = this.address1;
        if (this.address2) address = address + "<br>" + this.address2;
        if (this.address3) address = address + "<br>" + this.address3;
        if (this.postTown) address = address + "<br>" + this.postTown;
        if (this.county) address = address + "<br>" + this.county;
        if (this.postcode) address = address + "<br>" + this.postcode;
        return address;
    }

    get addressWithoutPostcode() {
        let address = this.address1;
        if (this.address2) address = address + "<br>" + this.address2;
        if (this.address3) address = address + "<br>" + this.address3;
        if (this.postTown) address = address + "<br>" + this.postTown;
        if (this.county) address = address + "<br>" + this.county;
        return address;
    }

    get addressNonHtml() {
        let address = this.address1;
        if (this.address2) address = address + ", " + this.address2;
        if (this.address3) address = address + ", " + this.address3;
        if (this.postTown) address = address + ", " + this.postTown;
        if (this.county) address = address + ", " + this.county;
        if (this.postcode) address = address + ", " + this.postcode;
        return address;
    }

    get canChase(): boolean {
        return !this.doNotChase;
    }
    set canChase(value: boolean) {
        this.doNotChase = !value;
    }
}

